import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);
const store = new Vuex.Store({
    state: {    // 存储token    
    },
    mutations: {    // 修改token，并将token存入localStorage    
    }
});
export default store;