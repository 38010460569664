<template>
  <div id="app">
    <div v-wechat-title="$route.meta.title"></div>
    <!-- <router-view v-if="$route.meta.keepalive" /> -->
    <keep-alive>
      <router-view v-if="$route.meta.keepalive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepalive"></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="less" scoped>
#app {
  background-color: #ffffff;
}
</style>
